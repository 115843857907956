<template>
	<page-header-wrapper>
		<template slot="extra">
			<a-button type="primary" @click="exportExcel">导出数据</a-button>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper">
				
			</div>

			<s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" :scroll="{x:'100%'}"
			 showPagination="auto" class="">
				<span slot="time" slot-scope="time">
					{{time|time}}
				</span> 
				<span slot="nums" slot-scope="record">
					<span>正确:{{record.right_count}}</span>
					<a-divider type="vertical" />
					<span>错误:{{record.wrong_count}}</span>
				</span>
			</s-table>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import moment from 'moment'
	import {
		Modal,
		message
	} from 'ant-design-vue'
	import {
		STable,
		Ellipsis
	} from '@/components'
	import {
		getJoinList,
		
	} from '@/api/answer'
	import Clipboard from 'clipboard'
	import {
		prefix,
		getSource,
		getUrlKey
	} from '@/utils'
	const columns = [
		{
			title: '参与人',
			dataIndex:'nickname',
			width: '150px',
			align: 'center'
		},
		{
			title: '参与时间',
			dataIndex:'createTime',
			scopedSlots: {
				customRender: 'time'
			},
			width: '150px',
			align: 'center'
		},
		{
			title: '答题情况',
			dataIndex:'',
			scopedSlots: {
				customRender: 'nums'
			},
			width: '150px',
			align: 'center'
		},
	]

	export default {
		name: '',
		components: {
			STable,
			Ellipsis
		},
		data() {
			this.columns = columns
			return {
				// create model
				visible: false,
				advanced: false,
				prefix:prefix,
				// 查询参数
				queryParam: {
					keyword: '',
					tid:getUrlKey('id')
				},
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					//console.log('parameter',parameter)
					const requestParameters = Object.assign({}, parameter, this.queryParam)
					//console.log('requestParameters',requestParameters)
					return getJoinList(requestParameters)
						.then(res => {
							return res.data
						})
				}
			}
		},
		methods: {
			exportExcel(){
				let url = this.prefix+'/newAdmin.php?c=operate&a=exportExcel&tid='+getUrlKey('id')
				window.open(url)
			}
		},
		filters: {
			time(t) {
				if (t) {
					return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:mm')
				} else {
					return t
				}
			}
		}
	}
</script>


<style scoped>
	.tab_main {}

	.tab_main_title {
		margin-right: 10px;
	}

	.tab_main_block {
		margin-right: 10px;
		cursor: pointer;
	}

	.tab_main_act {
		color: #1890ff;
	}

	.content_line1 {
		margin-bottom: 15px;
	}

	.content_line1_title {
		font-weight: 600;
		font-size: 16px;
		color: #000;
	}

	.content_line2 {}

	.content_line2_data {}

	.content_line2_data i {
		font-style: normal;
		color: #f50;
		margin: 0 5px;
	}
</style>
